.Card {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(209, 213, 219, 0.3);
  border-radius: 12px;
  color: #1e262b;
  padding: 1.5rem;
  text-decoration: none;
  transition: 0.4s ease;
}

.Card:hover {
  background-color: rgba(255, 255, 255, 0.9);
  color: #252a60;
  transform: scale(1.05);
  transition: 0.4s ease;
}

.Card__header {
  font-weight: 600;
}
