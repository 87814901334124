/* .AnchorButton {
    align-items: center;
    display: flex;
    justify-content: center;
} */

.AnchorButton__anchor {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(209, 213, 219, 0.3);
  border-radius: 12px;
  color: #414aaf;
  display: inline-block;
  padding: 0.75em 1.5em;
  text-align: center;
  text-decoration: none;
  transition: 0.4s ease;
  width: 100%;
}

.AnchorButton__anchor:hover {
  background-color: rgba(255, 255, 255, 0.9);
  color: #252a60;
  transform: scale(1.05);
  transition: 0.4s ease;
}

.AnchorButton__icon {
  aspect-ratio: 1 / 1;
  object-fit: center;
  width: 100%;
  height: 100%;
  max-width: 2.5rem;
}
