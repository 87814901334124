.App {
  align-items: center;
  background-image: url('assets/sky.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  min-height: 100vh;
  padding: 1.5rem;
  width: 100%;
}

.App__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  max-width: 1500px;
  width: 100%;
}

.App__grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  width: 100%;
}

.App__footer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@media (min-width: 44em) {
  .App__container {
    justify-content: flex-end;
  }

  .App__grid > .Profile {
    grid-column: span 2;
    grid-row: span 2;
  }
}
