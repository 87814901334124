.Profile {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(209, 213, 219, 0.3);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
}

.Profile__header--primary {
  font-size: 3rem;
  font-weight: 600;
}

.Profile__header--secondary {
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.Profile__links {
  align-items: center;
  display: flex;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

@media (max-width: 44em) {
  .Profile__links {
    align-items: unset;
    flex-direction: column;
  }
}

.Profile__links > .AnchorButton > .AnchorButton__anchor {
  background-color: rgba(255, 255, 255, 0.1);
}

.Profile__links > .AnchorButton > .AnchorButton__anchor:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.discord__button {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(209, 213, 219, 0.3);
  border-radius: 12px;
  color: #414aaf;
  display: inline-block;
  height: fit-content;
  padding: 0.75em 1.5em;
  text-align: center;
  text-decoration: none;
  transition: 0.4s ease;
}

.discord__button:hover {
  background-color: rgba(255, 255, 255, 0.9);
  color: #252a60;
  cursor: pointer;
  transform: scale(1.05);
  transition: 0.4s ease;
}

.underline {
  box-shadow: inset 0 -0.4em #414aaf80;
  font: inherit;
}
