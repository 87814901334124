.Footer {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 44em) {
  .Footer {
    justify-content: center;
  }

  .Footer__container {
    width: 100%;
  }
}

.Footer__container {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(209, 213, 219, 0.3);
  border-radius: 12px;
  padding: 1.5rem;
  text-align: center;
}

.Footer__anchor {
  color: #414aaf;
  text-decoration: none;
  transition: 0.4s ease;
}

.Footer__anchor:hover {
  color: #252a60;
  transition: color 0.4s ease;
}
